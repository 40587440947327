//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { CyclePermissions } from '@/modules/cycle/cycle-permissions';
import { i18n } from '@/vueI18n';
import moment from 'moment';

import { CycleModel } from '@/modules/cycle/cycle-model';
const { fields } = CycleModel;

export default {
  name: 'app-cycle-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },
  props: {
    status: {
      type: String,
    },
  },

  data() {
    return {
      date: '',
      filter: {
        type: 'company',
      },
      selection: [],
      nextPage: 2,
      tablePagination: {
        page: 1,
        rowsPerPage: 10,
        sortBy: 'createdAt',
        descending: false,
        // rowsNumber: xx if getting data from a server
      },
      columns: [
        {
          name: 'name',
          field: 'name',
          label: i18n('Name'),
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'totalDeposit',
          field: 'totalDeposit',
          label: i18n('Company Profit'),
          align: 'center',
        },
        {
          name: 'totalWithdraw',
          field: 'totalWithdraw',
          label: i18n('Saryah Profit'),
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label: i18n('common.action'),
          align: 'center',
        },
      ],
      rows: [
        {
          name: 'Ahmed',
          totalDeposit: '2000 SAR',
          totalWithdraw: '1000 SAR',
        },
        {
          name: 'Ahmed',
          totalDeposit: '2000 SAR',
          totalWithdraw: '1000 SAR',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // rows: 'cycle/list/rows',
      // count: 'cycle/list/count',
      loading: 'wallet/list/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      // pagination: 'cycle/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
    }),
    hasPermissionToEdit() {
      return new CyclePermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
    pagesNumber() {
      return Math.ceil(
        this.rows.length / this.pagination.rowsPerPage,
      );
    },
  },
  methods: {
    ...mapActions({
      doChangeSort: 'cycle/list/doChangeSort',
      doChangePaginationCurrentPage: 'cycle/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'cycle/list/doChangePaginationPageSize',
      doMountTable: 'wallet/list/doMountTable',
      doFetch: 'cycle/list/doFetch',
    }),
    checkValue(val, reason, details) {
      console.log(val, reason, details);
      this.date = this.date.substring(0, 7);
      if (reason === 'month') {
        console.log(this.date);
        this.$refs.monthPicker.hide();
      }
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = CycleModel.presenter(row, fieldName);
      switch (fieldName) {
        case 'total':
          return this.currentLanguageCode == 'ar'
            ? `${val} ريال سعودي ` 
            : `${val} SAR`  
        case 'receiptNum':
          return this.currentLanguageCode == 'ar' ? `${val} #` : `# ${val}`
        default:
          return val
      }
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      // return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = CycleModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterCycle(row, fieldName, key) {
      const user = CycleModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null;
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length
            ? user[key][0].publicUrl
            : null;
        } else if (user && user[key]) {
          avatar = user[key].publicUrl;
        }
        return avatar
          ? avatar
          : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c';
      }
      return user && user[key] ? user[key] : 'ـــ';
    },

    async doFetchFirstPage() {
      return this.doFetch({
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
        },
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      const pagination = {
        sortBy: 'desc',
        limit: 10,
        doc: firstDocument,
        action: 'prev'
      }
      return this.doFetch({
        filter: { type: this.type },
        pagination
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      const pagination = {
        sortBy: 'desc',
        limit: 10,
        doc: lastDocument,
        action: 'next'
      }
      return this.doFetch({
        filter: { type: this.type },
        pagination
      });
    },
  },
};
