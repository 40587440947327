//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import CycleListTable from '@/modules/cycle/components/cycle-list-table.vue';

export default {
  name: 'app-cycle-list-page',

  components: {
    [CycleListTable.name]: CycleListTable,
  },

  data() {
    return {
      tab: 'company',
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      doFetch: 'cycle/list/doFetch',
    }),
  },
};
